import { createMedia } from '@artsy/fresnel'
import { stripUnit } from 'polished'
import { theme } from 'theme'

const { Media, MediaContextProvider, createMediaStyle } = createMedia({
  breakpoints: {
    xs: stripUnit(theme.breakpoints.xs),
    sm: stripUnit(theme.breakpoints.sm),
    md: stripUnit(theme.breakpoints.md),
    lg: stripUnit(theme.breakpoints.lg),
    xl: stripUnit(theme.breakpoints.xl),
  },
})

const mediaStyles = createMediaStyle()

export { Media, MediaContextProvider, mediaStyles }
