import { stripUnit } from 'polished'
import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  breakpoints: {
    xs: '0px',
    sm: '706px',
    md: '898px',
    lg: '1122px',
    xl: '1330px',
  },
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    blue: '#0078c9',
    red: '#f9464e',
    porcelain: '#f7f8f9',
    lilyWhite: '#e7f8ff',
    regalBlue: '#00446d',
    foam: '#f0fafe',
  },
}

export const breakpoints = {
  xs: stripUnit(theme.breakpoints.xs),
  sm: stripUnit(theme.breakpoints.sm),
  md: stripUnit(theme.breakpoints.md),
  lg: stripUnit(theme.breakpoints.lg),
  xl: stripUnit(theme.breakpoints.xl),
}
